import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import {graphql, Link} from "gatsby"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <Layout location={this.props.location} title={"focus"}>
        <Seo
          title="focus"
          keywords={[
            "focus",
            "timer",
            "pomodoro",
            "ios",
            "work",
            "productivity",
            "mobile",
            "app",
          ]}
        />
        <img
          src="https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/95/50/40/955040ce-3e30-a106-d955-d18fd3020606/AppIcon-0-85-220-4-2x.png/230x0w.webp"
          alt="Download on the App Store"
          className="apps-focus-icon"
        />
        <h1>focus - work and study timer</h1>
        <p>
          focus app is a simple pomodoro timer that does not distract you from
          actually being focused on what matters.
        </p>
        <p>With focus you can:</p>
        <p>
          <ul>
            <li>Track your work or study sessions</li>
            <li>Get notified when it's time for a short break</li>
            <li>Skip short breaks if you are in the zone</li>
            <li>Customize session durations to your liking</li>
          </ul>
        </p>
        <p>
          The app is available for both iOS and macOS. On macOS it conveniently
          sits in your menubar, showing you the timer.
        </p>
        <p>Use the focus app now, be more productive and achieve more!</p>
        <p>
          <a
            href="https://apps.apple.com/us/app/focus-work-and-study-timer/id1602415401?itsct=apps_box_badge&amp;itscg=30200"
            className="apps-focus-button-embed-anchor"
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1642032000&h=d3991a6b08b11bb74de59d18dee626fc"
              alt="Download on the App Store"
              className="apps-focus-button-embed-image"
            />
          </a>
        </p>
        <h3>Behind the scenes</h3>
        <p>
          The focus app is a native iOS, iPadOS, watchOS, and macOS app written
          in Swift.
        </p>
        <p>
          The app uses combination of SwiftUI and classic AppKit/UIKit
          frameworks to achieve functionalities impossible to be implemented
          with SwiftUI.
        </p>
        <p>I wrote the app to have fun and learn Swift in the first place.</p>
        <p>
          If you are using the app and have any feature suggestions or found any
          issues, feel free to reach out to me.
        </p>
        <Link to={`/apps/focus/privacy_policy`} itemProp="url">
          <p>Privacy Policy</p>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
